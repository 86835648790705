<template>
  <main class="notice">
    <div class="notice_wrap">
      <div class="notice_input">
        <form @submit.prevent="search">
          <div class="notice_search_box">
            <input
              type="text"
              placeholder="검색"
              v-model="search_keyword"
              @keyup.enter="search()"
            />
            <button class="notice_search_btn">
              <span class="ir_pm" @click="search()">버튼</span>
            </button>
          </div>
        </form>
      </div>
      <table class="notice_table">
        <thead>
          <tr>
            <th width="80%">제목</th>
            <th>작성일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="100" align="center" v-if="this.items.length == 0">
              검색 결과가 없습니다.
            </td>
          </tr>
          <tr v-for="item in items" :key="item.seq" @click="detail(item.seq)">
            <td>
              [{{ item.topYn == "Y" ? "공지" : "일반" }}] {{ item.title }}
            </td>
            <td>{{ item.regDtTm }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="totalPages ? totalPages : 1"
        use-router
        v-model="currentPage"
        :limit="10"
      ></b-pagination-nav> -->
      <base-pagination
        :total="totalItems"
        :loading="contentLoading"
        :currentPage="currentPage"
        :pageCount="totalPages"
        :perPage="pageSize"
        @change="read"
      />
    </div>
  </main>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  components: {
    BasePagination,
  },
  data() {
    return {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,

      url: "/api/board/notice",
      items: [],
      search_keyword: "",
    };
  },
  methods: {
    search() {
      let params = this.getData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/notice?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          path: "notice",
          query: params,
        });
      }
    },
    getData(page) {
      let params = {};
      if (this.search_keyword.length != 0) {
        params.search_keyword = this.search_keyword;
      }
      this.currentPage = page;
      params.page = this.currentPage;
      return params;
    },
    detail(seq) {
      this.$router.push({
        name: "NoticeSeq",
        params: {
          seq: seq,
        },
      });
    },
    read(page) {
      let params = this.getData(page);

      client.boardNoticeList(params).then(
        (response) => {
          const { data } = response;
          this.items = data.content;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    if (this.$route.query) {
      this.search_keyword = this.$route.query.search_keyword
        ? this.$route.query.search_keyword
        : "";
    }
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
